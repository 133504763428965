(function ($) {
    "use strict";

    $.events = new Object({
        init: function () {
            this.filterSelect();
            this.filter();
            this.removeEmptySessionList();
        },

        filterSpeaker: function (val) {
            $(".speaker__list-item").filter(function () {
                $(this).attr("data-filter").indexOf(val) === -1
                    ? $(this).addClass("d-none").removeClass("d-flex")
                    : $(this).addClass("d-flex").removeClass("d-none");
            });

            if ($(".speaker__list-item[data-filter]:visible").length <= 0) {
                $(".speaker-list-alert").removeClass("d-none");
            } else {
                $(".speaker-list-alert").addClass("d-none");
            }
        },

        filterSelect: function () {
            var that = this;

            $(".speaker__filter-select").on("change", function (e) {
                e.preventDefault();

                if ($(this).val() === "all") {
                    $(".speaker__list-item")
                        .addClass("d-flex")
                        .removeClass("d-none");
                    return;
                }

                var _val = $(this).val();
                that.filterSpeaker(_val);
            });
        },

        filter: function () {
            var that = this;

            $(".speaker__list-item").attr("tabindex", "-1");

            $(".js-filter").on("click", function (e) {
                e.preventDefault();

                if ($(this).data("filter") === "all") {
                    $(".js-filter").removeClass("active");
                    $(".js-filter").removeAttr("aria-pressed");
                    $(this).addClass("active");
                    $(this).attr("aria-pressed", "true");
                    $(".speaker__list-item")
                        .addClass("d-flex")
                        .removeClass("d-none")[0]
                        .focus({ preventScroll: true });
                    $(".speaker__list").attr(
                        "aria-labelledby",
                        $(this).attr("id")
                    );

                    return;
                }

                $(".js-filter").removeClass("active");
                $(".js-filter").removeAttr("aria-pressed");
                $(this).addClass("active");
                $(this).attr("aria-pressed", "true");
                $(".speaker__list").attr("aria-labelledby", $(this).attr("id"));

                var _val = $(this).data("filter");
                that.filterSpeaker(_val);

                $(".speaker__list-item:visible")[0].focus({
                    preventScroll: true,
                });
            });
        },

        removeEmptySessionList: function () {
            $(".programm-overview__sessions").each(function () {
                if ($(this).find("li").length <= 0) {
                    // $(this).parent().remove();
                    $(this).parent().remove();
                }
            });
        },
    });
})(jQuery);
