(function ($) {
    "use strict";

    $.masonryWrapper = new Object({
        masonry: null,

        init: function () {
            if ($(".ce-frame-type-theme_blog_overview").length > 0) {
                var masonryGrid = document.querySelector(".articles");
                if (typeof Masonry !== "undefined" && masonryGrid) {
                    // Init
                    this.masonry = new Masonry(masonryGrid, {
                        itemSelector: "#blog-list li",
                    });
                }
            }
            if ($(".ce-frame-type-theme_gallery").length > 0) {
                var masonryGrid = document.querySelector(
                    ".gallery__wrapper-inner"
                );
                if (typeof Masonry !== "undefined" && masonryGrid) {
                    // Init
                    this.masonry = new Masonry(masonryGrid, {
                        itemSelector: ".gallery__entry",
                        horizontalOrder: true,
                    });
                }
            }
        },

        update: function () {
            if (this.masonry !== null) {
                this.masonry.layout();
            }
        },
    });
})(jQuery);
