(function ($) {
    "use strict";

    $.youTube = new Object({
        iframe: function (uri) {
            return $("<iframe />")
                .attr("src", uri)
                .attr("allow", "autoplay; encrypted-media")
                .attr("framborder", 0)
                .attr("allowfullscreen", true)
                .attr("class", "aspect-ratio-xs-item");
        },

        init: function () {
            $(document).on(
                "click",
                "[data-youtube-overlay-url]",
                function (event) {
                    const _youtubeUri = $(this).attr(
                        "data-youtube-overlay-url"
                    );

                    $(this)
                        .parent()
                        .parent()
                        .html(
                            $(
                                '<div class="aspect-ratio-xs aspect-ratio-16by9" />'
                            ).prepend(
                                $.youTube.iframe(_youtubeUri + "?autoplay=1")
                            )
                        );
                }
            );

            const onYoutubeEnabled = (e) => {
                console.clear();
                console.log("enable all youtube videos on load");
                console.log(e);

                $.youTubeApi.init();

                $("[data-youtube-overlay-url]").each(function () {
                    const _youtubeUri = $(this).attr(
                        "data-youtube-overlay-url"
                    );

                    $(this)
                        .parent()
                        .parent()
                        .html(
                            $(
                                '<div class="aspect-ratio-xs aspect-ratio-16by9" />'
                            ).prepend($.youTube.iframe(_youtubeUri))
                        );
                });

                window.removeEventListener(
                    "youTubeAllowedGlobal",
                    onYoutubeEnabled
                );
            };

            window.addEventListener("youTubeAllowedGlobal", onYoutubeEnabled);

            window.addEventListener("bannerHide", function () {
                console.clear();
                console.log("banner hide");

                if ($(".gdpr-overlay").length > 0) {
                    document.location.reload();
                }
            });
        },
    });
})(jQuery);
