(function ($) {
    "use strict";

    $.tabbedContent = new Object({
        init: function () {
            if ($("#tabbedContent-select").length > 0) {
                $("#tabbedContent-select").on("change", function (e) {
                    var _tab = $(this).val();
                    $('a[href="' + _tab + '"]').click();
                });
            }
        },
    });
})(jQuery);
