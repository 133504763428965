(function ($) {
    "use strict";

    $.glightbox = new Object({
        init: function () {
            const showMoreBtn = document.getElementById("gallery-showMoreBtn");
            if (showMoreBtn) {
                showMoreBtn.addEventListener("click", function (e) {
                    const galleryHiddenEntries = document.querySelectorAll(
                        ".gallery__entry.hidden"
                    );
                    if (galleryHiddenEntries) {
                        e.preventDefault();
                        for (const [key, value] of Object.entries(
                            galleryHiddenEntries
                        ).slice(0, 18)) {
                            value.classList.remove("hidden");
                            value.classList.add("show");
                            $.masonryWrapper.update();
                            if (
                                !galleryHiddenEntries[
                                    galleryHiddenEntries.length - 1
                                ].classList.contains("hidden")
                            ) {
                                showMoreBtn.classList.add("d-none");
                            }
                        }
                    }
                });
            }

            const customLightboxHTML = `
                <div id="glightbox-body" class="glightbox-container" tabindex="-1" role="dialog" aria-hidden="false">
                    <div class="gloader visible"></div>
                    <div class="goverlay"></div>
                    <div class="gcontainer">
                        <div id="glightbox-slider" class="gslider"></div>
                        <div>
                            <a href="#" download="" title="" id="gdownload" target="_blank" class="gdownload gbtn | d-flex justify-content-center align-items-center border-0" aria-label="Download image" data-taborder="3">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="44" viewBox="0 0 22 21" width="44">
                                    <path clip-rule="evenodd" d="M5.85764 8.14935L4.44388 9.57314L11.025 16.1542L17.6066 9.57259L16.1919 8.15788L12.0278 12.3146L12.0278 0.958984H10.0222L10.0222 12.3139L5.85764 8.14935ZM2.01978 13.5677H0.106445V20.6465H21.9435V13.5677H20.0302V18.7332H2.01978V13.5677Z" fill="black" fill-rule="evenodd"></path>
                                </svg>
                            </a>

                            <button class="gclose gbtn" aria-label="Close" data-taborder="4">
                                <svg width="60" height="60" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path id="icon-close" d="M39.091 4.75454L35.2455 0.909088L20.0001 16.1545L4.75463 0.909088L0.90918 4.75454L16.1546 20L0.90918 35.2454L4.75463 39.0909L20.0001 23.8454L35.2455 39.0909L39.091 35.2454L23.8455 20L39.091 4.75454Z" fill="black"/>
                                </svg>
                            </button>
                        </div>

                        <button class="gprev gbtn" aria-label="Previous" data-taborder="2">
                            <svg width="30" height="30" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path id="icon-arrow-small" d="M0.454989 11.6932L14.976 11.6932L8.30612 18.3631L10.0004 20.0455L19.5459 10.5L10.0004 0.954547L8.31806 2.63693L14.976 9.30682L0.454989 9.30682L0.454989 11.6932Z" fill="black"/>
                            </svg>
                        </button>

                        <button class="gnext gbtn" aria-label="Next" data-taborder="1">
                            <svg width="30" height="30" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path id="icon-arrow-small" d="M0.454989 11.6932L14.976 11.6932L8.30612 18.3631L10.0004 20.0455L19.5459 10.5L10.0004 0.954547L8.31806 2.63693L14.976 9.30682L0.454989 9.30682L0.454989 11.6932Z" fill="black"/>
                            </svg>
                        </button>

                        <span id="gcount" class="gcount gbtn" aria-hidden="true"></span>
                    </div>
                </div>
            `;

            const lightbox = GLightbox({
                selector: ".glightbox",
                lightboxHTML: customLightboxHTML,
                moreLength: 0,
            });

            lightbox.on("open", () => {
                const counterEl = document.getElementById("gcount");
                let currentIndex = lightbox.getActiveSlideIndex() + 1;
                if (counterEl && currentIndex) {
                    const totalSlides = lightbox.elements.length;
                    counterEl.innerHTML = `${currentIndex} | ${totalSlides}`;
                    setDownloadBtn(currentIndex);
                }
            });

            lightbox.on("slide_before_change", () => {
                const totalSlides = lightbox.elements.length;
                const counterEl = document.getElementById("gcount");
                let currentIndex = lightbox.getActiveSlideIndex() + 1;
                if (counterEl && currentIndex) {
                    counterEl.innerHTML = `${currentIndex} | ${totalSlides}`;
                    setDownloadBtn(currentIndex);
                }
            });

            function setDownloadBtn(index) {
                const downloadBtn = document.getElementById("gdownload");
                const downloadBtnMobile = document.getElementById(
                    `image-download-${index}`
                );
                if (downloadBtn && downloadBtnMobile) {
                    downloadBtn.setAttribute(
                        "href",
                        downloadBtnMobile.getAttribute("href")
                    );
                    downloadBtn.setAttribute(
                        "download",
                        downloadBtnMobile.getAttribute("download")
                    );
                    downloadBtn.setAttribute(
                        "title",
                        downloadBtnMobile.getAttribute("title")
                    );
                }
            }
        },
    });
})(jQuery);
