(function ($) {
    "use strict";

    $.cssVariables = new Object({
        init: function () {
            $("[data-css-var]").each(function () {
                var _json = $(this).data("cssVar"),
                    that = $(this);

                if (typeof _json === "object") {
                    $.each(_json, function (k, v) {
                        document.documentElement.style.setProperty("--" + k, v);
                    });

                    if (_json["navigation-init-color"]) {
                        var _elem = document.querySelector(
                            "#header > .main-header"
                        );
                        if (_elem) {
                            _elem.style.setProperty(
                                "--navigation-init-color",
                                _json["navigation-init-color"]
                            );
                        }

                        /*var _elem = document.querySelector(".website-logo");
                        if (_elem) {
                            _elem.style.setProperty(
                                "--logo-init-color",
                                _json["navigation-init-color"]
                            );
                        }*/
                    }
                }
            });
        },
    });
})(jQuery);
