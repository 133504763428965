(function ($) {
    "use strict";

    $.navigation = new Object({
        _sticky: false,
        _scrollPos: window.scrollY,

        init: function () {
            var that = this;

            // Initial aria-state for the menu button in closed state.
            var menuContainer = $(".burger");
            var menuButton = $(".hamburger");
            menuButton.attr("aria-expanded", "false");
            // Initial aria-state and style for the overlay
            // for hiding the closed burger menu (also for the keyboard).
            // visibility: hidden; is the same like display: none;
            // withouth removing the space for the transition animation.
            // Always remember: The current focus is always the 'eye' of
            // the screen reader user and visual indicator (if styled accordingly)
            // for the keyboard only user.
            var overlay = $(".overlay");
            overlay.css({ visibility: "hidden" });

            function isMenuOpen() {
                return menuButton.attr("aria-expanded") === "true";
            }

            var focusTrap = function (el) {
                var tabbable = el
                    .find("select, input, textarea, button, a")
                    .filter(":visible");

                var firstTabbable = tabbable.first();
                var lastTabbable = tabbable.last();

                // set focus on first focusable element
                setTimeout(function () {
                    firstTabbable.focus();
                }, 100);

                // redirect last tab to first focusable element
                lastTabbable.on("keydown", function (e) {
                    if (e.which === 9 && !e.shiftKey) {
                        e.preventDefault();
                        firstTabbable.focus();
                    }
                });

                // redirect first shift+tab to last focusable element
                firstTabbable.on("keydown", function (e) {
                    if (e.which === 9 && e.shiftKey && isMenuOpen()) {
                        e.preventDefault();
                        lastTabbable.focus();
                    }
                });

                // allow escape key to close the menu via keyboard
                $(document).on("keyup", function (e) {
                    if (e.keyCode === 27 && isMenuOpen()) {
                        if (menuButton.attr("aria-expanded") === "true") {
                            menuButton.click();
                        }
                    }
                });
            };

            menuButton.on("click", function (e) {
                e.preventDefault();
                $("body").toggleClass("no-scroll");
                $(this).toggleClass("is-active");

                // conditional aria-states and style
                // opening or closing the menu.
                if ($(this).attr("aria-expanded") === "false") {
                    $(this).attr("aria-expanded", "true");
                    overlay.css({ visibility: "visible" });
                    overlay.attr("aria-hidden", "false");
                    focusTrap(menuContainer);
                } else {
                    $(this).attr("aria-expanded", "false");
                    overlay.css({ visibility: "hidden" });
                    overlay.attr("aria-hidden", "true");
                    $(this).focus();
                }

                if (that._scrollPos <= 0) {
                    $(window).scrollTop($(window).scrollTop() + 1);
                    $("#header .main-header").toggleClass("sticky");
                } else {
                    $(window).scrollTop($(window).scrollTop() - 1);
                }
            });

            $(".navigation .has-sub")
                .children("button")
                .attr("aria-expanded", "false");
            $(document).on(
                "click",
                ".navigation .has-sub > a, .navigation .has-sub > button",
                function (e) {
                    e.preventDefault();
                    $(".navigation .has-sub > a, .navigation .has-sub > button")
                        .not(this)
                        .removeClass("is-active");
                    $(".navigation .has-sub > a, .navigation .has-sub > button")
                        .not(this)
                        .attr("aria-expanded", "false");
                    $(this).toggleClass("is-active");
                    $(this).attr("aria-expanded", function (i, attr) {
                        return attr === "true" ? "false" : "true";
                    });
                    if ($(this).attr("aria-expanded") === "true") {
                        $(this)
                            .next()
                            .children(":first-child")
                            .children(":first-child")
                            .focus();
                    }
                }
            );
        },
    });
})(jQuery);
