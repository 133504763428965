(function ($) {
    "use strict";

    $.stickyNavigation = new Object({
        init: function () {
            var _scrollPos = $(document).scrollTop();
            // var _logo = document.querySelector(".website-logo");

            if (_scrollPos > 0) {
                $("#header .main-header").addClass("sticky");

                /*if (_logo) {
                    _logo.style.setProperty("--logo-init-color", "#000");
                }*/
            } else {
                $("#header .main-header").removeClass("sticky");

                // getComputedStyle(document.querySelector('#header .main-header')).getPropertyValue('--navigation-init-color');

                /*
                if (_logo) {
                    _logo.style.setProperty(
                        "--logo-init-color",
                        getComputedStyle(
                            document.querySelector("#header .main-header")
                        ).getPropertyValue("--navigation-init-color")
                    );
                }*/
            }
        },
    });
})(jQuery);
