(function ($) {
    "use strict";

    $.formValidation = new Object({
        init: function () {
            var forms = $("form.needs-validation");

            $.each(forms, function (k, form) {
                $(form).on("submit", function (e) {
                    if (form.checkValidity() === false) {
                        e.preventDefault();
                        e.stopPropagation();
                    }

                    $(form).addClass("was-validated");
                });
            });
        },
    });
})(jQuery);
