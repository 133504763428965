(function ($) {
    "use strict";

    $.livestream = new Object({
        open: function (el) {
            var _vimeoRegex = /(vimeo(pro)?.com)\/(?:[^\d]+)?(\d+)\??(.*)?$/,
                _uri = el.attr("href").replaceAll("%2F", "/"),
                _matches = _vimeoRegex.exec(_uri),
                _opts = {};

            // use other vimeoHandler, if the livestream is
            // a vimeo livestream url
            // like: vimeo.com/event/[$VIMEO-ID]/embed
            if (_matches && _matches[4] && _matches[4].startsWith("/embed")) {
                _opts = {
                    handlers: {
                        vimeo: $.livestream.vimeoHandler,
                    },
                };
            }

            var instance = lity(_uri, _opts);
        },

        vimeoHandler: function (target) {
            const newTarget = target.replace(
                "https://vimeo.com",
                "https://vimeo.com/event"
            );

            return (
                '<div class="lity-iframe-container"><iframe frameborder="0" allowfullscreen allow="autoplay; fullscreen" src="' +
                newTarget +
                '" style="max-width: 100%;"/></div>'
            );
        },
    });
})(jQuery);
