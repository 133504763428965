(function () {
    function customHistoryPush(state, title, url, ...args) {
        const event = new CustomEvent("historyStateChange", {
            detail: {
                args,
                state,
                title,
                url,
                type: "pushState",
            },
        });
        history.pushState(state, title, url, ...args);
        window.dispatchEvent(event);
    }

    function customHistoryReplace(state, title, url, ...args) {
        const event = new CustomEvent("historyStateChange", {
            detail: {
                args,
                state,
                title,
                url,
                type: "replaceState",
            },
        });
        history.replaceState(state, title, url, ...args);
        window.dispatchEvent(event);
    }

    function tabs(
        tablistContainerClass,
        tabContentClass,
        tabContentContainerClass,
        tabIdPrefix,
        selectClass
    ) {
        // ***************************************************
        // CONSTANTS
        // ***************************************************
        const OPTION_ID_PREFIX = "opt";
        const BUTTON_ID_PREFIX = "btn";
        const PANEL_ID_PREFIX = "panel";
        const QUERY_PREFIX = "tab_";

        // ***************************************************
        // VARIABLES
        // ***************************************************
        const tabListContainers = Array.from(
            document.querySelectorAll(tablistContainerClass)
        );
        const tabPanelContainers = Array.from(
            document.querySelectorAll(tabContentContainerClass)
        );
        const tabButtons = getAllTabButtons();
        const tabPanels = getAllTabPanels();
        const tabSelect = selectClass
            ? Array.from(document.querySelectorAll(selectClass))
            : [];
        const tabSelectOptions = getAllTabSelectOptions();

        // ***************************************************
        // GETTER
        // ***************************************************
        function getAllTabButtons() {
            return tabListContainers.reduce((buttons, container) => {
                const containerTabButtons = Array.from(
                    container.querySelectorAll("a")
                );
                buttons.push(...containerTabButtons);
                return buttons;
            }, []);
        }

        function getAllTabLists() {
            return tabListContainers.map((container) =>
                container.querySelector("ul")
            );
        }

        function getAllTabPanels() {
            return tabPanelContainers.reduce((panels, container) => {
                const containerPanels = Array.from(
                    container.querySelectorAll(tabContentClass)
                );
                panels.push(...containerPanels);
                return panels;
            }, []);
        }

        function getAllTabSelectOptions() {
            return tabSelect.reduce((options, select) => {
                const selectOptions = Array.from(
                    select.querySelectorAll("option")
                );
                options.push(...selectOptions);
                return options;
            }, []);
        }

        function getOptionId(index) {
            return `${tabIdPrefix}-${OPTION_ID_PREFIX}-${index}`;
        }

        function getButtonId(index) {
            return `${tabIdPrefix}-${BUTTON_ID_PREFIX}-${index}`;
        }

        function getPanelId(index) {
            return `${tabIdPrefix}-${PANEL_ID_PREFIX}-${index}`;
        }

        function getActiveIndex() {
            const url = new URL(window.location);
            let index = url.searchParams.get(QUERY_PREFIX + tabIdPrefix);
            if (index) {
                index = parseInt(index);
                if (!isNaN(index)) {
                    return index;
                }
            }
            return 0;
        }

        function getFirstPanelIndexInVisibleContainer() {
            const visibleContainer = tabPanelContainers.find((container) => {
                const containerBounds = container.getBoundingClientRect();
                return containerBounds.width > 0 || containerBounds.height > 0;
            });
            return (
                tabPanels?.findIndex((panel) =>
                    visibleContainer.contains(panel)
                ) ?? -1
            );
        }

        function getUrlWithUpdatedSearchParams(index) {
            const url = new URL(window.location);
            url.searchParams.set(QUERY_PREFIX + tabIdPrefix, index);
            return url;
        }

        function getContainerOfPanel(panel) {
            return tabPanelContainers.find((container) =>
                container.contains(panel)
            );
        }

        // ***************************************************
        // METHODS
        // ***************************************************
        function hideTabPanel(panel) {
            panel.hidden = true;
            panel.classList.remove("show", "active");
        }

        function hideAllTabPanels() {
            tabPanels.forEach((panel) => hideTabPanel(panel));
        }

        function showTabPanel(panel) {
            panel.hidden = false;
            panel.classList.add("show", "active");
        }

        function hideTabButton(button) {
            button.removeAttribute("aria-selected");
            button.setAttribute("tabindex", "-1");
            button.classList.remove("active");
        }

        function hideAllTabButtons() {
            tabButtons.forEach((button) => hideTabButton(button));
        }

        function showTabButton(button) {
            button.removeAttribute("tabindex");
            button.setAttribute("aria-selected", "true");
            button.classList.add("active");
        }

        function updateSelectOption(option) {
            const selectElement = option.closest("select");
            if (selectElement) {
                selectElement.value = option.value;
            }
        }

        function setTabPanelsA11yAttributes() {
            const isAnySelectVisible = !!tabSelect.find(
                (select) => window.getComputedStyle(select).display !== "none"
            );
            tabPanels.forEach((panel, index) => {
                if (isAnySelectVisible) {
                    panel.removeAttribute("role");
                    panel.setAttribute("aria-labelledby", getOptionId(index));
                } else {
                    panel.setAttribute("role", "tabpanel");
                    panel.setAttribute("aria-labelledby", getButtonId(index));
                }
            });
        }

        function setActivePanelByIndex(index) {
            const url = getUrlWithUpdatedSearchParams(index);
            customHistoryPush({ [tabIdPrefix]: index }, "", url);
        }

        function setActivePanelByContainerVisibility() {
            const panelIndex = getFirstPanelIndexInVisibleContainer();
            if (panelIndex >= 0) {
                const url = getUrlWithUpdatedSearchParams(panelIndex);
                customHistoryReplace({ [tabIdPrefix]: panelIndex }, "", url);
            }
        }

        function isPanelInVisibleContainer(panel) {
            const container = getContainerOfPanel(panel);
            const containerBounds = container?.getBoundingClientRect();
            return containerBounds?.width > 0 || containerBounds?.height > 0;
        }

        function updatePanelVisibility() {
            const nextVisibleIndex = getActiveIndex();

            if (
                tabPanels.length <= nextVisibleIndex ||
                tabButtons.length <= nextVisibleIndex ||
                !isPanelInVisibleContainer(tabPanels[nextVisibleIndex])
            ) {
                setActivePanelByContainerVisibility();
                return;
            }

            hideAllTabPanels();
            hideAllTabButtons();
            showTabPanel(tabPanels[nextVisibleIndex]);
            showTabButton(tabButtons[nextVisibleIndex]);

            if (tabSelectOptions.length > nextVisibleIndex) {
                updateSelectOption(tabSelectOptions[nextVisibleIndex]);
            }
        }

        // ***************************************************
        // SETUP
        // ***************************************************
        (function setup() {
            const tabLists = getAllTabLists();
            tabLists.forEach((list) => {
                list.setAttribute("role", "tablist");
            });
            tabPanels.forEach((panel, index) => {
                panel.id = getPanelId(index);
                panel.setAttribute("tabindex", "-1");
            });
            tabButtons.forEach((button, index) => {
                button.setAttribute("role", "tab");
                button.parentElement.setAttribute("role", "presentation");
                button.id = getButtonId(index);
                button.href = "#" + getPanelId(index);
            });
            tabSelectOptions.forEach((option, index) => {
                option.id = getOptionId(index);
                option.value = "#" + getPanelId(index);
                option.dataset.index = index;
            });
            setTabPanelsA11yAttributes();
            updatePanelVisibility();
        })();

        // ***************************************************
        // EVENT LISTENERS
        // ***************************************************
        tabButtons.forEach((button, index) => {
            button.addEventListener("click", (event) => {
                event.preventDefault();
                setActivePanelByIndex(index);
            });
            button.addEventListener("keydown", (event) => {
                const index = getActiveIndex();
                const direction =
                    event.code === "ArrowLeft"
                        ? index - 1
                        : event.code === "ArrowRight"
                        ? index + 1
                        : event.code === "ArrowDown"
                        ? "down"
                        : null;
                if (direction !== null) {
                    event.preventDefault();
                    if (direction === "down") {
                        tabPanels[index].focus();
                    } else {
                        const targetIndex = Math.min(
                            tabButtons.length - 1,
                            Math.max(0, direction)
                        );
                        if (targetIndex !== index) {
                            setActivePanelByIndex(targetIndex);
                            tabButtons[targetIndex].focus();
                        }
                    }
                }
            });
        });
        tabSelect.forEach((select) => {
            select.addEventListener("change", () => {
                if (select.selectedIndex >= 0) {
                    const selectedOption = select.options[select.selectedIndex];
                    const tabIndex = parseInt(selectedOption.dataset.index);
                    if (!isNaN(tabIndex)) {
                        setActivePanelByIndex(tabIndex);
                    }
                }
            });
        });
        window.addEventListener("popstate", () => updatePanelVisibility());
        window.addEventListener("historyStateChange", () =>
            updatePanelVisibility()
        );
        window.addEventListener("resize", () => setTabPanelsA11yAttributes());
    }

    document.addEventListener("DOMContentLoaded", () => {
        if (document.querySelector(".main-nav-tabs")) {
            tabs(
                ".main-nav-tabs",
                ".tab-pane__outer",
                ".tab-content__outer",
                "outer",
                ".nav-select"
            );
        }
        tabs(
            ".inner-nav-tabs",
            ".tab-pane__inner",
            ".tab-content__inner",
            "inner"
        );
    });
})();
