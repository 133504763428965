// the supseven main javascript file

(function ($) {
    $.youTube.init();

    let openVideoOnToggle = false;
    let isBannerOpen = false;

    window.addEventListener("supiInitEnd", () => {
        openVideoOnToggle = !isBannerOpen;
    });
    window.addEventListener("bannerShow", () => {
        isBannerOpen = true;
        openVideoOnToggle = false;
    });
    window.addEventListener("bannerHide", () => {
        setTimeout(() => {
            openVideoOnToggle = true;
            isBannerOpen = false;
        }, 20);
    });

    window.supiLiveStreamCallback = function (el) {
        $("[data-container=supiLiveStreamNotice]")
            .removeClass("d-block")
            .addClass("d-none");

        if (openVideoOnToggle) {
            $.livestream.open($(el).find("[data-toggle=supiServiceContainer]"));
        }
    };

    window.youtubeServiceCallback = function () {
        console.warn("do we need this?");
        if (
            $("[data-container=supiYoutubeNotice]").find(
                "[data-youtube-overlay-url]"
            ).length <= 0
        ) {
            $("[data-container=supiYoutubeNotice]")
                .removeClass("d-block")
                .addClass("d-none");
        }
    };

    // $(document).on("click", "[data-youtube-overlay-url]", function (event) {
    //     console.log("blafoo")
    //     const btn = $(event.target).closest("[data-youtube-overlay-url]");
    //     btn.attr("href", btn.attr("data-youtube-overlay-url"));
    //     $.livestream.open(btn);
    // });

    $.breakpoints.init();
    $.stickyNavigation.init();
    $.cssVariables.init();
    $.lightbox.init();
    $.navigation.init();
    $.masonryWrapper.init();
    $.formValidation.init();
    $.events.init();
    //$.tabbedContent.init();
    $.splide.init();
    $.tooltip.init();
    // $.cookiebot.init();
    $.glightbox.init();

    $(window).on("resize", function () {
        $.youTubeApi.init();

        $.masonryWrapper.update();
    });

    $(window).on("scroll", function () {
        $.stickyNavigation.init();
    });
})(jQuery);
