(function ($) {
    "use strict";

    $.cookiebot = new Object({
        init: function () {
            // give all external media the data-attribute for cookie consent
            $(
                '.js-livestream, [data-url*="playout.3qsdn.com"], [href*="playout.3qsdn.com"], [href*="vimeo.com"]'
            )
                .attr("data-cookieconsent", "marketing")
                .addClass("no-consent")
                .addClass("tooltip");

            $.tooltip.init();

            window.addEventListener(
                "CookiebotOnConsentReady",
                function (e) {
                    $.each(Cookiebot.consent, function (consent, status) {
                        $.cookiebot.changeStatus(consent, status);
                    });
                },
                false
            );

            window.addEventListener(
                "CookiebotOnAccept",
                function (e) {
                    $.each(Cookiebot.consent, function (consent, status) {
                        $.cookiebot.changeStatus(consent, status);
                    });
                },
                false
            );
        },

        changeStatus: function (consent, status) {
            var _elem = $('a[data-cookieconsent="' + consent + '"]');
            if (status === true) {
                _elem.removeClass("no-consent").removeClass("tooltip");

                $.tooltip.removeText(_elem);
            } else {
                _elem.addClass("no-consent").addClass("tooltip");

                $.tooltip.init();
                $.tooltip.addText(_elem);
            }
        },
    });
})(jQuery);
