(function ($) {
    "use strict";

    $.youTubeApi = new Object({
        _videoElement: $("#player"),
        _youtubeApi: "https://www.youtube.com/iframe_api",
        _player: null,
        _youtubeId: null,
        _title: null,
        _origin: null,

        init: function () {
            if ($.breakpoints.breakpointDown("md") !== true) {
                if (this._videoElement.length > 0) {
                    this._title = this._videoElement.attr("title");
                    this._origin = this._videoElement.data("origin");

                    if (
                        $('script[src="' + this._youtubeApi + '"]').length <= 0
                    ) {
                        var _script = $("head script")[0];

                        if (_script) {
                            var _insertApi = $("<script />")
                                .attr("src", this._youtubeApi)
                                .attr("async", true);
                            $("head").append(_insertApi);
                        }
                    }

                    this._youtubeId = this._videoElement.data("ytId");
                }
            }
        },

        onYouTubeIframeAPIReady: function () {
            var title = this._title;
            var origin = this._origin;

            this._player = new YT.Player("player", {
                videoId: this._youtubeId,
                width: 1440,
                height: 700,
                playerVars: {
                    playlist: this._youtubeId,
                    autoplay: 1,
                    disablekb: 1,
                    controls: 0,
                    showinfo: 0,
                    modestbranding: 1,
                    loop: 1,
                    fs: 0,
                    rel: 0,
                    enablejsapi: 1,
                    wmode: "opaque",
                    autohide: 1,
                    iv_load_policy: 3,
                    origin: origin,
                },
                events: {
                    onReady: function (e) {
                        e.target.mute();
                        e.target.setPlaybackQuality("hd1080");
                        $("#player").attr("title", title);

                        var event = e,
                            stopped = false;

                        // pause video on lightbox opening
                        $('a[href*="youtube.com"][target="_blank"]').on(
                            "click",
                            function (e) {
                                event.target.stopVideo();
                                stopped = true;
                            }
                        );

                        // restart video when lightbox is closed
                        $(document).on("click", ".lity-close", function () {
                            if (stopped === true) {
                                event.target.playVideo();
                            }
                        });

                        $(document).on("keyup", function (key) {
                            if ($(".lity-close").length > 0) {
                                if (stopped === true) {
                                    event.target.playVideo();
                                }
                            }
                        });

                        setTimeout(function () {
                            // $('.cover-image').addClass('animate-opacity animate-opacity-0');
                            // $('.yt-video').addClass('animate-opacity animate-opacity-1');

                            setTimeout(function () {
                                $(".cover-image").addClass("d-lg-none");
                                $(".yt-video").addClass("d-lg-block");
                            }, 500);
                        }, 0);
                    },
                },
            });
        },
    });
})(jQuery);

//  var Xsa = "BASE_URL BASE_YT_URL adformat allow_embed authuser autoplay cc_load_policy cpn dash dashmpd disable_native_context_menu disablekb docid el enable_cardio enable_faster_speeds enablecastapi end fmt_list hl hlsdvr hlsvp iurl iurlhq iurlmq length_seconds live_playback osig override_hl partnerid pipable playsinline plid post_live_playback postid ps public rel start startSeconds status suggestedQuality timestamp title token ttsurl use_native_controls url_encoded_fmt_stream_map video_id videoId".split(" ")
//       , Ysa = "adformat allow_embed authuser autohide autonav autoplay c cbr cbrver cc_load_policy controls cos cosver cr csi_page_type cver cyc dash dashmpd disablekb el enable_cardio enablecastapi enablecsi end external_fullscreen external_list fs hl host_language innertube_api_key innertube_api_version innertube_context_client_version iurl iurlhq iurlmq iv_load_policy jsapicallback length_seconds live_playback live_storyboard_spec loaderUrl osig override_hl pageid partnerid pipable player_params player_wide playsinline plid postid profile_picture ps rel show_miniplayer_button showinfo showwatchlater start startSeconds status storyboard_spec suggestedQuality timestamp title tkn token transparent_background ucid url_encoded_fmt_stream_map use_fast_sizing_on_watch_default use_miniplayer_ui use_native_controls videoId video_id vss_host watermark".split(" ");
//
