(function ($) {
    "use strict";

    $.lightbox = new Object({
        init: function () {
            if (typeof lity === "undefined") {
                console.warn("LITY Lightbox Module not loaded.");
                return void 0;
            }

            this.default();
            this.hero();
            this.session();
            this.close();
            this.stored();
            this.pagination();

            if (
                location.hash.indexOf("#/") !== -1 &&
                location.hash.indexOf("/s/") !== -1
            ) {
                var _uri = location.hash.substr(1);
                this.createLightbox(_uri);
            }

            window.addEventListener("historyStateChange", function (e) {});

            window.addEventListener("popstate", function (e) {
                $.lightbox.historyLoad();
            });
        },

        customHistoryReplace: function (state, title, url, ...args) {
            const event = new CustomEvent("historyStateChange", {
                detail: {
                    args,
                    state,
                    title,
                    url,
                    type: "replaceState",
                },
            });
            history.pushState(state, title, url, ...args);
            window.dispatchEvent(event);
        },

        stored: function () {
            // Store the clicked element to return after closing dialog (lightbox)
            var storedElm = undefined;

            $(document).on("click", ".js-lightbox", function (e) {
                storedElm = e.currentTarget;
            });

            $(document).on("click", ".lightbox-close", function (e) {
                if (storedElm === undefined) {
                    return;
                }

                storedElm.focus();
                storedElm = undefined;
            });

            $(document).on("keyup", function (e) {
                if (e.key === "Escape") {
                    if (storedElm === undefined) return;

                    storedElm.focus();
                    storedElm = undefined;
                }
            });
        },

        hero: function () {
            // @TODO: Focus Management wie bei der Speaker-List, falls Focus nach dem Schließen der Lightbox nicht zum Ursprung zurückkehren sollte.
            $(document).on(
                "click",
                '.ce-frame-type-theme_hero a.btn[href*="youtube.com"]',
                function (e) {
                    const _supiOverlay = document.querySelector(
                        ".ce-frame-type-theme_hero .tx-supi__youtube"
                    );
                    const getYoutubeId = (href) => {
                        let id = null;

                        if (href) {
                            const url = new URL(href);
                            if (url) {
                                const params = url.searchParams;
                                if (params) {
                                    id = params.get("v");
                                }
                            }
                        }

                        return id;
                    };

                    const _id = getYoutubeId($(this).attr("href"));

                    if (_supiOverlay) {
                        _supiOverlay.dataset.youtubeId = _id;
                        _supiOverlay.dataset.youtubeUrl =
                            "https://www.youtube-nocookie.com/embed/" + _id;
                    } else {
                        const _iframe = document.querySelector(
                            ".ce-frame-type-theme_hero .lity-hide iframe"
                        );
                        _iframe.src =
                            "https://www.youtube-nocookie.com/embed/" + _id;
                    }

                    lity("#youtube-hero");

                    e.preventDefault();
                }
            );
        },

        session: function () {
            // @TODO: Focus Management wie bei der Speaker-List, falls Focus nach dem Schließen der Lightbox nicht zum Ursprung zurückkehren sollte.
            $(document).on(
                "click",
                '.ce-frame-type-list-bbboilerplate_pi2 a.btn.btn-youtube[href*="youtube.com"]',
                lity
            );
        },

        close: function () {
            $(document).on("click", ".lightbox-close", function (e) {
                $(".lightbox").remove();
                $("body").removeClass("lightbox-open");
                location.hash = "";
            });

            $(document).on("keyup", function (e) {
                if (e.key === "Escape") {
                    $(".lightbox").remove();
                    $("body").removeClass("lightbox-open");

                    location.hash = "";
                }
            });
        },

        focusTrap: function () {
            var el = $(".lightbox-content");

            function isModalOpen() {
                return typeof el !== "undefined";
            }

            if (!isModalOpen) {
                return;
            }

            // Add element itself also to tabbable because it has tabindex="-1"
            var tabbable = $.merge(
                el,
                el.find("select, input, textarea, button, a").filter(
                    // jquery :visible does not work on elements with visibility hidden
                    function () {
                        return !(
                            $(this).css("visibility") === "hidden" ||
                            $(this).css("display") === "none"
                        );
                    }
                )
            );

            var firstTabbable = tabbable.first();
            var lastTabbable = tabbable.last();
            //firstTabbable.focus(); focus will be set after ajax loading in this.default();

            lastTabbable.on("keydown", function (e) {
                if (e.key === "Tab" && !e.shiftKey && isModalOpen()) {
                    e.preventDefault();
                    firstTabbable.focus();
                }
            });

            firstTabbable.on("keydown", function (e) {
                if (e.key === "Tab" && e.shiftKey && isModalOpen()) {
                    e.preventDefault();
                    lastTabbable.focus();
                }
            });
        },

        default: function () {
            $(document).on("click", ".js-lightbox", function (e) {
                e.preventDefault();
                $.lightbox.createLightbox($(this).attr("href"));
            });
        },

        createLightbox: function (href) {
            var _lightbox = $('<div class="lightbox" />').append(
                $(
                    '<div class="lightbox-loading" aria-live="assertive" />'
                ).html(
                    '<div class="lightbox-loader"></div><span class="sr-only">' +
                        TYPO3.lang["lightbox.loading"] +
                        "</span>"
                )
            );

            $("body").append(_lightbox);
            $("body").addClass("lightbox-open");

            $.ajax({
                url: href,
                success: function (content) {
                    $(".lightbox-loading").remove();
                    $(".lightbox").addClass("loaded");

                    var _template = $('<div class="lightbox-content" />')
                        .attr("role", "dialog")
                        .attr("aria-labelledby", "speaker-name")
                        .attr("tabindex", "-1")
                        .append(
                            $('<div class="lightbox-content__inner" />').append(
                                '<div class="lightbox-content__inner-content">' +
                                    content +
                                    "</div>"
                            )
                        )
                        .append(
                            $('<button class="lightbox-close" />')
                                .append(
                                    '<svg width="40" height="40"><use xlink:href="#icon-close"/></svg>'
                                )
                                .append(
                                    $('<span class="sr-only" />').text(
                                        TYPO3.lang["lightbox.close"]
                                    )
                                )
                        );

                    $(".lightbox").append(_template);
                    $.lightbox.updatePagination();
                    $.lightbox.updateUri(href);
                    $.lightbox.focusTrap();
                    $(".lightbox-content").focus();
                },
            });
        },

        updateUri: function (uri) {
            this.customHistoryReplace("", "", "#" + uri);
        },

        updatePagination: function () {
            var _activeUid = $(".speaker-detail").data("uid"),
                _activeListUid = $(
                    '.speaker__list [data-uid="' + _activeUid + '"]'
                );

            if (_activeListUid) {
                var _prev = _activeListUid.prevAll(":visible").first(),
                    _next = _activeListUid.nextAll(":visible").first();

                if (_prev.length > 0) {
                    $(".lightbox__prev .lightbox__prev-title").text(
                        _prev.find(".speaker__heading").text()
                    );
                } else {
                    $(".lightbox__prev").addClass("invisible");
                }

                if (_next.length > 0) {
                    $(".lightbox__next .lightbox__next-title").text(
                        _next.find(".speaker__heading").text()
                    );
                } else {
                    $(".lightbox__next").addClass("invisible");
                }
            }
        },

        pagination: function () {
            var that = this;

            $(document).on("click", ".lightbox__pagination", function (e) {
                e.preventDefault();
                var _activeUid = $(this).closest("[data-uid]").data("uid");

                if (_activeUid) {
                    var _uid = $(
                        '.speaker__list [data-uid="' + _activeUid + '"]'
                    );

                    if (_uid) {
                        var _elem = $(this).hasClass("lightbox__prev")
                            ? _uid.prevAll(":visible").first()
                            : _uid.nextAll(":visible").first();

                        if (_elem) {
                            var _href = _elem.find(".js-lightbox").attr("href");
                        }
                    }
                }

                if (_href) {
                    that.ajax(_href);
                }

                requestAnimationFrame(function () {
                    $(".lightbox-content").focus();
                });
            });
        },

        historyLoad: function () {
            if (
                location.hash.indexOf("#/") !== -1 &&
                location.hash.indexOf("/s/") !== -1
            ) {
                var _uri = location.hash.substr(1);
                this.ajax(_uri);
            }
        },

        ajax: function (uri) {
            $(".lightbox").append(
                $(
                    '<div class="lightbox-loading lightbox-loading__inline" aria-live="assertive" />'
                ).html(
                    '<div class="lightbox-loader"></div><span class="sr-only">' +
                        TYPO3.lang["lightbox.loading"] +
                        "</span>"
                )
            );

            $.ajax({
                url: uri,
                success: function (content) {
                    $(".speaker-detail").animate(
                        {
                            opacity: 0,
                        },
                        250,
                        function () {
                            $(".lightbox-loading").remove();
                            $(".speaker-detail").remove();
                            $(".lightbox-content__inner-content").append(
                                content
                            );
                            $.lightbox.updatePagination();
                            $(".lightbox-content").focus();
                        }
                    );

                    $.lightbox.updateUri(uri);
                },
            });
        },
    });
})(jQuery);
