(function ($) {
    "use strict";

    $.tooltip = new Object({
        init: function () {
            var _text = TYPO3.lang["dsgvo.overlay"];
            $(".tooltip").find(".sr-only").remove();

            $(".tooltip").each(function () {
                var _elem = $("<span />").addClass("sr-only").text(_text);

                $(this).prepend(_elem);
                $(this).attr("data-tooltip", _text);
            });
        },

        addText: function (elem) {
            var _text = TYPO3.lang["dsgvo.innertext"];
            elem.find(".cookie-consent-inner-text").remove();
            elem.append(
                '<small class="pl-hg cookie-consent-inner-text">' +
                    _text +
                    "</small>"
            );
        },

        removeText: function (elem) {
            elem.find(".cookie-consent-inner-text").remove();
        },
    });
})(jQuery);
